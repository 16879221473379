import { isDonationFormEnabled } from "./utils";

(function(){
    document.addEventListener('alpine:init', () => {
        if (! isDonationFormEnabled()) {
            return;
        }

        const dform_args = window.fr_dform_args;

        Alpine.store('amountFixDefault', {
            small: dform_args.amount_settings.single.small,
            medium: dform_args.amount_settings.single.medium,
            large: dform_args.amount_settings.single.large,
        });

        Alpine.store('amountLimits', {
            min: dform_args.donation_amount_min,
            big: dform_args.donation_amount_big,
            max: dform_args.donation_amount_max,
        });

        /**
         * The formula is correct!!!
         * @param net A net amount
         * @param percent The percent (of a payment gateway) to compensate
         * @return {number} The necessary initial amount to get the requested net amount
         *                  as a result after gateway commission is applied
         */
        const getCompensatedAmount = (net, percent) => {
            // const p1 = 1 - percent;
            // const p2 = net / p1;
            // const p3 = Math.round(p2);
            // console.log(p1, p2, p3);
            // return p3;

            return Math.round(net / (1 - percent));
        };

        Alpine.data('donationAmount', () => {
            const SELECTION_KEYS = ['small', 'medium', 'large'];

            return {
                amountNet: {
                    small: 0,
                    medium: 0,
                    large: 0,
                    flex: null,
                },
                amountDisplay: {
                    small: 0,
                    medium: 0,
                    large: 0,
                    flex: null,
                },
                amount: {
                    error: false,
                    blurred: false,
                },
                warning: {
                    show: false,
                    max: 0,
                    min: 0,
                },
                selection: 'medium',
                isCustomChosen: false,
                pay_comission: false,
                commission: null,

                init: function () {
                    this.setCommission();

                    for (const key of SELECTION_KEYS) {
                        this.amountNet[key] = this.$store.amountFixDefault[key];
                        this.amountDisplay[key] = this.amountNet[key];
                    }

                    // this.fieldsData is a parent component prop
                    this.fieldsData.amount = this.amountDisplay[this.selection];

                    const defaultInputSelector = `#${this.selection}-hint`;
                    this.$root.querySelector(defaultInputSelector).click()

                    this.setLot();

                    this.warning.min = this.$store.amountLimits.min;
                    this.warning.big = this.$store.amountLimits.big;
                    this.warning.max = this.$store.amountLimits.max;
                },

                /**
                 * Handle "onchange" of an input that has a "fixed amount"
                 * @param {HTMLInputElement} input
                 * @param {string} selection
                 */
                changeSelection: function(input, selection) {
                    console.log(input, selection);
                    if (input.checked) {
                        this.isCustomChosen = false;
                        this.amount.error = false;
                        this.selection = selection;

                        this.setLot();
                    }
                },

                onGatewayChanged() {
                    this.recalculateAmounts();
                },

                updateFixDefaults: function () {
                    let percent = parseFloat(this.commission);

                    for (const key of SELECTION_KEYS) {
                        this.amountNet[key] = this.$store.amountFixDefault[key];

                        if (this.pay_comission) {
                            this.amountDisplay[key] = getCompensatedAmount(this.amountNet[key], percent);
                        } else {
                            this.amountDisplay[key] = this.amountNet[key];
                        }
                    }
                },

                setCommission() {
                    this.commission = this.$store.donationForm.commission;
                },

                /**
                 * Checkbox switching logic
                 */
                updateComission: function () {
                    this.pay_comission = ! this.pay_comission;
                    this.recalculateAmounts();
                },

                recalculateAmounts() {
                    let percent = parseFloat(this.commission);

                    if (this.pay_comission) {
                        // increase
                        for (const key of SELECTION_KEYS) {
                            this.amountDisplay[key] = getCompensatedAmount(this.amountNet[key], percent);
                        }

                        if (this.amountDisplay.flex !== null) {
                            this.amountDisplay.flex = getCompensatedAmount(this.amountNet.flex, percent);
                        }
                    } else {
                        // reduce
                        for (const key of SELECTION_KEYS) {
                            this.amountDisplay[key] = this.amountNet[key];
                        }

                        if (this.amountDisplay.flex !== null) {
                            this.amountDisplay.flex = this.amountNet.flex;
                        }
                    }
                },

                /**
                 * "focus" event on input:radio of "custom amount" field
                 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/beforeinput_event
                 */
                onFocusCustomAmount(e) {
                    // remove visual "active" state from other radio buttons
                    this.selection = 'flex';
                    this.setLot();

                    this.isCustomChosen = true;
                    setTimeout(() => this.$refs.flex_variant.focus(), 50);
                },

                /**
                 * "blur" event on input:text of "custom amount" field
                 */
                onBlurCustomAmount: function(e) {
                    const $input = e.target;

                    // zero (number) as "false" case would be more logical here,
                    // but some UX inconsistencies were found
                    const value = $input.value?.length ? parseInt($input.value) : '';

                    this.amount.blurred = true;
                    this.selection = 'flex';

                    const lessThanMin = value < this.warning.min;
                    const biggerThanMax = value > this.warning.max;
                    const isInvalid = lessThanMin || biggerThanMax;

                    const isBig = value > this.warning.big;

                    this.amount.error = isInvalid;
                    this.warning.show = isBig && ! isInvalid;
                    this.setupFlexVariant(value);
                },

                setupFlexVariant: function (value) {
                    let percent = parseFloat(this.commission);
                    let netvalue = value;

                    // If a donator changes "Custom amount" when "pay_comission" mode is enabled
                    // we change Net "Custom amount" according to the new inserted/compensated "Custom amount"
                    // so if the donator disables "pay_comission" mode
                    // he'll get a new Net "Custom amount" close/related to the latest inserted/compensated "Custom amount"
                    if (this.pay_comission) {
                        netvalue =  Math.round(value * (1 - percent));
                    }

                    this.amountDisplay.flex = value;
                    this.amountNet.flex = netvalue;
                },

                // not used for now
                clearFlexVariant: function () {
                    this.amountDisplay.flex = null;
                    this.amountNet.flex = null;
                },

                /**
                 * Use only after the this.selection has been set/changed correctly
                 */
                setLot: function () {
                    // fieldsData & lotSpec are props of "donationForm" component
                    this.fieldsData.lot = this.lotSpec[this.selection];
                },
            };
        });
    });

})();
